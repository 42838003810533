import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// utils
import { useRecognition } from './providers';
import { translate } from '../../i18n/i18n';

// components
import MessageTile from './MessageTile';
import { Button, Empty } from 'antd';

// height calc 72px - 24px - 65px - 62px - 20px
const MainWrapper = styled.div`
  height: ${(props) =>
    props.isDrawer
      ? props.showCoinsForm
        ? 'calc(100vh - 343px)'
        : 'calc(100vh - 145px)'
      : props.showCoinsForm
      ? 'calc(100vh - 210px - 190px)'
      : 'calc(100vh - 210px)'};
  background-color: #fff;
  overflow: auto;
  // border-bottom: 1px solid #e2e3e3;
  padding-top: 10px;
`;

const ShowMoreText = styled.div`
  // margin-top: 10px;
  text-align: center;
`;

const EmptyWrapper = styled.div`
  height: ${(props) =>
    props.isDrawer
      ? props.showCoinsForm
        ? 'calc(100vh - 343px)'
        : 'calc(100vh - 145px)'
      : props.showCoinsForm
      ? 'calc(100vh - 210px - 190px)'
      : 'calc(100vh - 210px)'};
  background-color: #fff;
  overflow: none;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const t = translate(['recognition', 'common']);

const Message = forwardRef((props, ref) => {
  const { isDrawer } = props;
  const messagesEndRef = useRef(null);
  const {
    page,
    showCoinsForm,
    messageList,
    messageLoading,
    showMore,
    setPage,
  } = useRecognition();

  useEffect(() => {
    scrollToBottom();
  }, []);

  useImperativeHandle(ref, () => ({
    scrollDown() {
      scrollToBottom();
    },
  }));

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onShowMore = () => {
    if (!showMore) return;
    setPage(page + 1);
  };

  if (!messageList.length && !messageLoading) {
    return (
      <EmptyWrapper isDrawer={isDrawer} showCoinsForm={showCoinsForm}>
        <Empty icon={<></>} description={t('noMessage')} />
      </EmptyWrapper>
    );
  }

  return (
    <MainWrapper
      id="scrollableDiv"
      isDrawer={isDrawer}
      showCoinsForm={showCoinsForm}
      // style={
      //   messageList.length > 5
      //     ? {
      //         display: 'flex',
      //         flexDirection: 'column-reverse',
      //       }
      //     : {}
      // }
    >
      {/* <InfiniteScroll
        dataLength={30}
        next={() => {
          console.log('next calling', hasMore);
          fetchData();
        }}
        hasMore={hasMore}
        height={'calc(100vh - 145px)'}
        scrollableTarget="scrollableDiv">
        </InfiniteScroll> */}
      {showMore && (
        <ShowMoreText onClick={() => onShowMore()}>
          <Button loading={messageLoading} type="link">
            {t('showMore')}
          </Button>
        </ShowMoreText>
      )}
      {messageList.map((element, index) => (
        <MessageTile key={element.id} element={element} index={index} />
      ))}
      <div ref={messagesEndRef} />
    </MainWrapper>
  );
});

Message.propTypes = {
  // loading: PropTypes.bool,
  // fetchData: PropTypes.func,
  // hasMore: PropTypes.bool,
  isDrawer: PropTypes.bool,
};

export default Message;
