import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component
import { Button, Form, Input, Select } from 'antd';
import InputNumber from './common/InputNumber';

// Icons
import { CloseCircleOutlined } from '@ant-design/icons';
import CoinsIcon from './../assets/coins.svg';

const StyledForm = styled(Form)`
  &.ant-form {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

const StyledInput = styled(Input)`
  background: #f2f6f9;
  border-radius: 4px;
  border: none;
  height: 40px;

  &&&.ant-input-status-error {
    border: 1px solid #ff4d4f;
  }
`;

const StyledSelect = styled(Select)`
  &&& .ant-select-selector {
    display: flex;
    align-items: center;
    background-color: #f2f6f9;
    border-radius: 4px;
    border: none;
    height: 40px;
  }
  .ant-select-selection-search {
    top: 5px;
  }
  &&&.ant-select-status-error {
    border: 1px solid #ff4d4f;
    border-radius: 4px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  background-color: #ffac33;
  border-radius: 4px;
`;

const SubmitText = styled.span`
  margin-left: 5px;
`;

const SubmitTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  margin-left: 10px;
  border-radius: 4px;
`;

const SendCoinsForm = ({ users, occasion, onShowForm, onSendCoins }) => {
  const [form] = Form.useForm();

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onSubmit = async (values) => {
    const { message, coins } = values;
    onSendCoins({
      ...values,
      message: message.trim(),
      coins: coins?.number,
    });
    form.resetFields();
  };

  return (
    <StyledForm form={form} autoComplete="off" onFinish={onSubmit}>
      <Form.Item name="receiver" rules={[{ required: true, message: '' }]}>
        <StyledSelect
          showSearch
          placeholder="Select Receiver"
          options={users}
          filterOption={filterOption}
        />
      </Form.Item>
      <Form.Item name="occasion" rules={[{ required: true, message: '' }]}>
        <StyledSelect
          showSearch
          options={occasion}
          filterOption={filterOption}
          placeholder="Select Occasion"
        />
      </Form.Item>
      <Form.Item
        name="coins"
        rules={[
          {
            required: true,
            message: '',
            validator: (_, value) => {
              if (value?.number > 0) {
                return Promise.resolve();
              }
              return Promise.reject('');
            },
          },
        ]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="message" rules={[{ required: true, message: '' }]}>
        <StyledInput placeholder="Send a Message" />
      </Form.Item>
      <ButtonWrapper>
        <SubmitButton
          size="large"
          type="primary"
          htmlType="submit"
          onClick={() => form.submit()}>
          <SubmitTextWrapper>
            <img src={CoinsIcon} />
            <SubmitText>Send Coins</SubmitText>
          </SubmitTextWrapper>
        </SubmitButton>
        <CloseButton
          onClick={onShowForm}
          danger
          size="large"
          type="primary"
          icon={<CloseCircleOutlined />}>
          Cancel
        </CloseButton>
      </ButtonWrapper>
    </StyledForm>
  );
};

SendCoinsForm.propTypes = {
  users: PropTypes.array,
  occasion: PropTypes.array,
  onShowForm: PropTypes.func,
  onSendCoins: PropTypes.func,
};

export default SendCoinsForm;
