import React, { Suspense, useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  matchPath,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import './interceptors';
import i18n from './i18n/i18n';

// Component
import {
  Card,
  Col,
  ConfigProvider,
  Drawer,
  FloatButton,
  Layout,
  Row,
  Segmented,
} from 'antd';
import Header from './components/Layout/Header';
import FullPageLoader from './components/FullPageLoader';
import SuggestionBox from './components/SuggestionBox';
import Recognition from './components/Recognition';
import DemoSuggestionBox from './components/demo/SuggestionBox';
import DemoRecognition from './components/demo/Recognition';
import ErrorBoundary from './components/ErrorBoundary';

// Routes
import DefaultRouter from './router/default';
import DemoRouter from './router/demo';

// constant
import {
  API_URL,
  APP_ENV,
  DEFAULT_ACTIVE_SEGMENT,
  DEFAULT_ENVIRONMENT,
  TOKEN_KEY,
} from './constant';

// hooks
import useWindowSize from './hooks/window';

// icons
import { FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// utils
import { onSetLoading, onSetUser, onSetLoginLoading } from './store/user';
import { translate } from './i18n/i18n';
import theme, { colorPalette } from './theme';

const { Content } = Layout;

const t = translate(['common']);

const StyledContent = styled(Content)`
  padding: ${(props) => props.padding || '30px'};
  height: calc(100vh - 65px);
  overflow: auto;
  overflow-x: hidden;
`;

const StyledCard = styled(Card)`
  &.ant-card {
    border-radius: 0px;
    // height: calc(100vh - 60px);
  }
  .ant-card-body {
    padding-bottom: 0px;
  }
`;

const StyledSegment = styled(Segmented)`
  .ant-segmented-item-selected {
    background: #ecf5fe;
    border: 1px solid ${colorPalette.primary[500]};
    border-radius: 4px;
    color: ${colorPalette.primary[500]};
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px;
  }
`;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentUser, isLoading, loginLoading } = useSelector(
    (state) => state.user,
  );
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const unknownPaths = ['/not-found', '/unauthorized'];
  const [activeSegment, setActiveSegment] = useState(DEFAULT_ACTIVE_SEGMENT);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isLoginRoute = matchPath('/login/:slug', location.pathname);
  const isSessionRoute = matchPath('/session/:slug', location.pathname);
  const authToken = searchParams.get('token');

  useEffect(() => {
    if (APP_ENV !== DEFAULT_ENVIRONMENT.DEMO) {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!isLoginRoute && !authToken) {
        if (token) {
          fetchUserDetails();
        } else {
          navigate('/unauthorized');
          dispatch(onSetLoading(false));
          dispatch(onSetLoginLoading(false));
        }
      } else if (authToken) {
        dispatch(onSetLoading(true));
        fetchUserFromSlug();
      } else {
        dispatch(onSetLoading(false));
      }
    } else {
      dispatch(onSetLoading(false));
      dispatch(onSetLoginLoading(false));
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedLanguage = (currentUser?.company?.languages || []).find(
      (ele) => currentUser?.language === ele.id,
    );
    if (selectedLanguage?.code) {
      i18n.changeLanguage(selectedLanguage?.code);
    }
  }, [currentUser]);

  const fetchUserFromSlug = async () => {
    try {
      localStorage.removeItem(TOKEN_KEY);
      const params = {};
      if (isSessionRoute) {
        params.session = location.pathname.split('/session/')[1];
      }
      const { user, token } = await axios.get(
        `${API_URL}/accounts/profile/${authToken}/`,
        { params },
      );
      localStorage.setItem(TOKEN_KEY, token.auth_token);
      dispatch(onSetUser(user));
    } catch {
      navigate('/not-found');
    } finally {
      setSearchParams({}, { replace: true });
      dispatch(onSetLoading(false));
      dispatch(onSetLoginLoading(false));
    }
  };

  const fetchUserDetails = async () => {
    dispatch(onSetLoading(true));
    try {
      const response = await axios.get(`${API_URL}/accounts/profile/`);
      dispatch(onSetUser(response));
      if (unknownPaths.includes(location.pathname)) {
        navigate('/');
      }
      if (
        !response?.company?.suggestion_enabled &&
        response?.company?.recognition_enabled
      ) {
        setActiveSegment(1);
      }
    } catch (error) {
      navigate('/not-found');
    } finally {
      dispatch(onSetLoading(false));
      dispatch(onSetLoginLoading(false));
    }
  };

  const getBox = useCallback(() => {
    if (APP_ENV === DEFAULT_ENVIRONMENT.DEMO) {
      return activeSegment === 0 ? (
        <DemoSuggestionBox isDrawer={openDrawer} />
      ) : (
        <DemoRecognition isDrawer={openDrawer} />
      );
    }
    if (activeSegment === 0 && currentUser?.company?.suggestion_enabled) {
      return <SuggestionBox isDrawer={openDrawer} />;
    }
    if (activeSegment === 1 && currentUser?.company?.recognition_enabled) {
      return <Recognition isDrawer={openDrawer} />;
    }
  }, [activeSegment, currentUser]);

  const getSegmentItems = useCallback(() => {
    const data = [];
    if (currentUser?.company?.suggestion_enabled) {
      data.push({ label: t('suggestionBox'), value: 0 });
    }
    if (currentUser?.company?.recognition_enabled) {
      data.push({ label: t('recognition'), value: 1 });
    }
    return data;
  }, [currentUser]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (width <= 820) {
    return (
      <ConfigProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={<FullPageLoader />}>
            <Layout id="layout">
              <Header />
              <StyledContent padding="10px">
                {APP_ENV === DEFAULT_ENVIRONMENT.DEMO ? (
                  <DemoRouter />
                ) : (
                  <DefaultRouter />
                )}
              </StyledContent>
              {currentUser?.company?.suggestion_enabled && (
                <FloatButton
                  icon={<QuestionCircleOutlined />}
                  type="primary"
                  shape="square"
                  style={{
                    right: currentUser?.company?.recognition_enabled ? 55 : 10,
                  }}
                  onClick={() => {
                    setOpenDrawer(true);
                    setActiveSegment(0);
                  }}
                />
              )}
              {currentUser?.company?.recognition_enabled && (
                <FloatButton
                  icon={<FormOutlined />}
                  type="primary"
                  shape="square"
                  style={{ right: 10 }}
                  // tooltip={t('recognition')}
                  onClick={() => {
                    setOpenDrawer(true);
                    setActiveSegment(1);
                  }}
                />
              )}
              {(currentUser?.company?.suggestion_enabled ||
                currentUser?.company?.recognition_enabled) && (
                <StyledDrawer
                  title={
                    activeSegment === 1 ? t('recognition') : t('suggestionBox')
                  }
                  width={width}
                  onClose={() => {
                    setOpenDrawer(false);
                    setActiveSegment(0);
                  }}
                  open={openDrawer}
                  destroyOnClose>
                  {openDrawer && getBox()}
                </StyledDrawer>
              )}
            </Layout>
          </Suspense>
        </ErrorBoundary>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider theme={theme}>
      <ErrorBoundary>
        <Suspense fallback={<FullPageLoader />}>
          <Layout id="layout">
            <Header />
            <Row>
              <Col
                span={
                  unknownPaths.includes(location.pathname) ||
                  !(
                    currentUser?.company?.suggestion_enabled ||
                    currentUser?.company?.recognition_enabled
                  )
                    ? 24
                    : 16
                }>
                <StyledContent>
                  {APP_ENV === DEFAULT_ENVIRONMENT.DEMO ? (
                    <DemoRouter />
                  ) : (
                    <DefaultRouter />
                  )}
                </StyledContent>
              </Col>
              {(currentUser?.company?.suggestion_enabled ||
                currentUser?.company?.recognition_enabled) &&
                width > 768 &&
                !unknownPaths.includes(location.pathname) && (
                  <Col span={8}>
                    <StyledCard>
                      <StyledSegment
                        block
                        value={activeSegment}
                        options={getSegmentItems()}
                        onChange={(e) => setActiveSegment(e)}
                      />
                      <Wrapper>{getBox()}</Wrapper>
                    </StyledCard>
                  </Col>
                )}
            </Row>
          </Layout>
        </Suspense>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
