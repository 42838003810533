import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';

// components
import { Avatar, Tag, Tooltip } from 'antd';
import Comments from './Comments';

// utils
import { REACTION_TYPE } from '../../../constant';
import { getShortName, stringToColor } from '../../../utils';
// import { translate } from '../../../i18n/i18n';

// icons
import CoinsIcon from '../../../assets/coins.svg';
import { CommentOutlined } from '@ant-design/icons';
import { colorPalette } from '../../../theme';

// const t = translate(['common']);

const MainWrapper = styled.div`
  height: ${(props) =>
    props.isDrawer
      ? props.showCoinsForm
        ? 'calc(100vh - 343px)'
        : 'calc(100vh - 145px)'
      : props.showCoinsForm
      ? 'calc(100vh - 210px - 190px)'
      : 'calc(100vh - 210px)'};
  background-color: #fff;
  overflow: auto;
  // border-bottom: 1px solid #e2e3e3;
  padding-top: 10px;
`;

const MessageWrapper = styled.div`
  // display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e3e3;
  margin-bottom: 15px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props) =>
    props.name ? stringToColor(props.name) : '#841f8f'};
  font-size: 18px !important;
`;

const UserProfile = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDiv = styled.div`
  margin-left: 10px;
`;

const UserName = styled.p`
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
  color: #212121;
`;

const StyledMessage = styled.div`
  margin-left: 3rem;
`;

const MessageText = styled.div`
  border-left: 3px solid #37a19d;

  p {
    margin-left: 12px;
  }
`;

const ValueWrapper = styled.div`
  border-left: 3px solid #f0cc76;

  .ant-tag {
    text-transform: uppercase;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    color: #212121;

    :first-child {
      margin-left: 10px;
    }
  }
`;

const EmojiWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .ant-tag {
    cursor: pointer;
    background: #f5f5f5;
    border-radius: 20px;

    &.active {
      border: 1px solid ${colorPalette.primary[500]};
      background: #ecf5fe;
      color: ${colorPalette.primary[500]};
      font-size: 13px;
    }
  }
`;

const StyledTag = styled(Tag)`
  background: #f5f5f5;
  border-radius: 20px;
`;

const TooltipIcon = styled.span`
  font-size: 24px;
  display: block;
  text-align: center;
`;

const TooltipText = styled.div`
  font-weight: 500;
  small {
    font-weight: 300;
    color: #ecf5fe;
  }
`;

const StyledCoin = styled.img`
  background: #ffac33;
  padding: 2px;
  height: 20px;
`;

const ReplyText = styled.span`
  cursor: pointer;
`;

const DateText = styled.small`
  font-size: 12px;
  line-height: 14px;
  color: #617080;
`;

const StyledCommentOutlined = styled(CommentOutlined)`
  font-size: 20px;
`;

const Message = ({
  messages,
  showCoinsForm,
  onReaction,
  isDrawer,
  onSelectMessage,
  newComments,
}) => {
  const { currentUser } = useSelector((state) => state.user);
  const messagesEndRef = useRef(null);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getTooltipText = (users) => {
    const user = users.map((ele) => ele.name);
    const last = user.pop();
    return user.length
      ? `${user.join(', ')} <small>and</small> ${last} <small>reacted</small>.`
      : `${last} <small>reacted<small>.`;
  };

  const isReacted = (users, key) => {
    return (users[key] || []).find((ele) => ele.id === currentUser.id)
      ? true
      : false;
  };

  const formatMessage = (string, usersArray = [], tagsArray = []) => {
    usersArray.forEach((user) => {
      const nameRegex = new RegExp(`@${user.name}`, 'g');
      if (string.match(nameRegex)) {
        string = string.replace(nameRegex, `<b>${user.name}</b>`);
      }
    });

    tagsArray.forEach((tag) => {
      const tagRegex = new RegExp(`(?:\\s|^)[\\/#]${tag.name}\\b`, 'g');
      string = string.replace(tagRegex, '');
    });

    return string;
  };

  const getMessageText = (element) => {
    if (element?.coins > 1) {
      return (
        <p>
          sent <StyledCoin src={CoinsIcon} alt="coin" /> {element?.coins} to{' '}
          <b>{element?.receiver?.name}</b> on their{' '}
          <b>{element.occasion?.name}</b> with the following message :
          <br />
          <i>{element.message}</i>
        </p>
      );
    }
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            element?.message,
            element.mentioned_users,
            element.tags,
          ),
        }}
      />
    );
  };

  const getEmojiText = (element, index, key, value) => {
    const tagElement = (
      <Tag
        onClick={() => onReaction(index, key)}
        className={isReacted(element?.reaction?.users, key) && 'active'}>
        {value}{' '}
        {element?.reaction?.count[key] > 0 && element?.reaction?.count[key]}
      </Tag>
    );

    if (element?.reaction?.count[key] > 0) {
      return (
        <Tooltip
          key={key}
          placement="top"
          title={
            <>
              <TooltipIcon>{value}</TooltipIcon>
              <TooltipText
                dangerouslySetInnerHTML={{
                  __html: getTooltipText(element?.reaction?.users[key]),
                }}
              />
            </>
          }>
          {tagElement}
        </Tooltip>
      );
    }
    return tagElement;
  };

  return (
    <>
      <MainWrapper isDrawer={isDrawer} showCoinsForm={showCoinsForm}>
        {messages.map((element, index) => (
          <MessageWrapper key={element.id}>
            <TitleWrapper>
              <UserProfile>
                <StyledAvatar name={element?.user?.name} size="large">
                  {getShortName(element?.user?.name)}
                </StyledAvatar>
                <StyledDiv>
                  <UserName>{element?.user?.name}</UserName>
                  <small>
                    {element?.user?.job_title || element?.user?.email}
                  </small>
                </StyledDiv>
              </UserProfile>
              <DateText>{moment(element.created_at).fromNow()}</DateText>
            </TitleWrapper>
            <StyledMessage>
              <MessageText>{getMessageText(element)}</MessageText>
              {element?.values?.length > 0 && (
                <ValueWrapper>
                  {element?.values?.map((tag) => (
                    <StyledTag>{tag?.name}</StyledTag>
                  ))}
                </ValueWrapper>
              )}
              <EmojiWrapper
                style={showComments ? { marginBottom: '15px' } : {}}>
                <div>
                  {Object.entries(REACTION_TYPE).map(([key, value]) =>
                    getEmojiText(element, index, key, value),
                  )}
                </div>
                <ReplyText
                  onClick={() => {
                    onSelectMessage(showComments ? null : element);
                    setShowComments(showComments ? null : element);
                  }}>
                  <StyledCommentOutlined />
                </ReplyText>
              </EmojiWrapper>
              {showComments?.id === element?.id && (
                <Comments parentId={element?.id} newComments={newComments} />
              )}
            </StyledMessage>
          </MessageWrapper>
        ))}
        <div ref={messagesEndRef} />
      </MainWrapper>
    </>
  );
};

Message.propTypes = {
  messages: PropTypes.array,
  newComments: PropTypes.array,
  showCoinsForm: PropTypes.bool,
  onReaction: PropTypes.func,
  onSelectMessage: PropTypes.func,
  isDrawer: PropTypes.bool,
};

export default Message;
