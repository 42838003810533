import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';

// Component
import { Avatar, Tag, Tooltip } from 'antd';
import FullPageLoader from './../../FullPageLoader';

// Constant
import { API_URL, REACTION_TYPE } from './../../../constant';

// utils
import { getShortName, stringToColor } from './../../../utils';
import { getRecognitionComments } from '../../../data';
import { colorPalette } from '../../../theme';

const MessageWrapper = styled.div`
  margin-top: 4px;
  border-bottom: 1px solid rgb(226, 227, 227);
  background-color: #f2f6f9;
  padding: 12px;
  border-radius: 3px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props) =>
    props.name ? stringToColor(props.name) : '#841f8f'};
  font-size: 14px !important;
`;

const UserProfile = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDiv = styled.div`
  // margin-left: 10px;
`;

const EmailText = styled.small`
  position: relative;
  top: -6px;
`;

const UserName = styled.p`
  font-size: 13px;
  margin: 0px;
  font-weight: 600;
  color: #212121;
`;

const StyledMessage = styled.div`
  margin-left: 3rem;
`;

const MessageText = styled.div`
  border-left: 3px solid #37a19d;

  p {
    margin-left: 12px;
    white-space: pre-wrap;
  }
`;

const ValuesText = styled.span`
  margin: 0px 10px;
`;

const ValueWrapper = styled.div`
  border-left: 3px solid #f0cc76;

  .ant-tag {
    text-transform: uppercase;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    color: #212121;
  }
`;

const EmojiWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .ant-tag {
    cursor: pointer;
    background: #f5f5f5;
    border-radius: 20px;

    &.active {
      border: 1px solid ${colorPalette.primary[500]};
      background: #ecf5fe;
      color: ${colorPalette.primary[500]};
      font-size: 13px;
    }
  }
`;

const StyledTag = styled(Tag)`
  background: #f5f5f5;
  border-radius: 20px;
`;

const TooltipIcon = styled.span`
  font-size: 24px;
  display: block;
  text-align: center;
`;

const TooltipText = styled.div`
  font-weight: 500;
  small {
    font-weight: 300;
    color: #ecf5fe;
  }
`;

const StyledCoin = styled.img`
  background: #ffac33;
  padding: 2px;
  height: 20px;
`;

const DateText = styled.small`
  font-size: 12px;
  line-height: 14px;
  color: #617080;
`;

// const ShowMoreText = styled.div`
//   margin-top: 10px;
//   text-align: center;
// `;

const Comments = ({ newComments = [] }) => {
  const { currentUser } = useSelector((state) => state.user);

  const [commentsList, setCommentsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (newComments.length) {
      setCommentsList([...newComments, ...getRecognitionComments()]);
    }
  }, [newComments]);

  const fetchData = async () => {
    setLoading(true);
    setCommentsList(getRecognitionComments());
    setLoading(false);
  };

  const onReaction = async (index, key) => {
    const element = commentsList[index];
    const reactions = element?.reaction?.users[key] || [];
    const rIndex = reactions.findIndex((ele) => ele.id === currentUser.id);
    if (rIndex < 0) {
      commentsList[index].reaction?.users[key].push(currentUser);
      commentsList[index].reaction.count[key] += 1;
    } else {
      commentsList[index].reaction?.users[key].splice(rIndex, 1);
      commentsList[index].reaction.count[key] -= 1;
    }
    setCommentsList([...commentsList]);
    try {
      const data = {
        recognition_id: element?.id,
        reaction_type: key,
      };
      await axios.post(`${API_URL}/recognition/reaction`, data);
    } catch (e) {
      console.error(e);
    }
  };

  const getTooltipText = (users) => {
    const user = users.map((ele) => ele.name);
    const last = user.pop();
    return user.length
      ? `${user.join(', ')} <small>and</small> ${last} <small>reacted</small>.`
      : `${last} <small>reacted<small>.`;
  };

  const isReacted = (users, key) => {
    return (users[key] || []).find((ele) => ele.id === currentUser.id)
      ? true
      : false;
  };

  const formatMessage = (string, usersArray = [], tagsArray = []) => {
    usersArray.forEach((user) => {
      const nameRegex = new RegExp(`@${user.name}`, 'g');
      if (string.match(nameRegex)) {
        string = string.replace(nameRegex, `<b>${user.name}</b>`);
      }
    });

    tagsArray.forEach((tag) => {
      const tagRegex = new RegExp(`(?:\\s|^)[\\/#]${tag.name}\\b`, 'g');
      string = string.replace(tagRegex, '');
    });

    return string;
  };

  const getMessageText = (element) => {
    if (element?.coins > 1) {
      return (
        <p>
          sent <StyledCoin src={CoinsIcon} alt="coin" /> {element?.coins} to{' '}
          <b>{element?.receiver?.name}</b> on their{' '}
          <b>{element.occasion?.name}</b> with the following message :
          <br />
          <i>{element.message}</i>
        </p>
      );
    }
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            element?.message,
            element.mentioned_users,
            element.tags,
          ),
        }}
      />
    );
  };

  const getEmojiText = (element, index, key, value) => {
    const tagElement = (
      <Tag
        key={key}
        onClick={() => onReaction(index, key)}
        className={isReacted(element?.reaction?.users, key) && 'active'}>
        {value}{' '}
        {element?.reaction?.count[key] > 0 && element?.reaction?.count[key]}
      </Tag>
    );

    if (element?.reaction?.count[key] > 0) {
      return (
        <Tooltip
          key={key}
          placement="top"
          title={
            <>
              <TooltipIcon>{value}</TooltipIcon>
              <TooltipText
                dangerouslySetInnerHTML={{
                  __html: getTooltipText(element?.reaction?.users[key]),
                }}
              />
            </>
          }>
          {tagElement}
        </Tooltip>
      );
    }
    return tagElement;
  };

  if (loading) return <FullPageLoader />;

  return (
    <>
      {commentsList.map((element, index) => (
        <MessageWrapper key={element?.id}>
          <TitleWrapper>
            <UserProfile>
              <StyledAvatar name={element?.user?.name}>
                {getShortName(element?.user?.name)}
              </StyledAvatar>
              <StyledDiv>
                <UserName>{element?.user?.name}</UserName>
                <EmailText>
                  {element?.user?.job_title || element?.user?.email}
                </EmailText>
              </StyledDiv>
            </UserProfile>
            <DateText>{moment(element.created_at).fromNow()}</DateText>
          </TitleWrapper>
          <StyledMessage>
            <MessageText>{getMessageText(element)}</MessageText>
            {element?.tags?.length > 0 && (
              <ValueWrapper>
                <ValuesText>Values:</ValuesText>
                {element?.tags?.map((tag) => (
                  <StyledTag key={tag?.id}>{tag?.name}</StyledTag>
                ))}
              </ValueWrapper>
            )}
            <EmojiWrapper>
              <div>
                {Object.entries(REACTION_TYPE).map(([key, value]) =>
                  getEmojiText(element, index, key, value),
                )}
              </div>
            </EmojiWrapper>
          </StyledMessage>
        </MessageWrapper>
      ))}
    </>
  );
};
Comments.propTypes = {
  newComments: PropTypes.array,
};
export default Comments;
