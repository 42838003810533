import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import RecognitionMessage from './Message';
import RecognitionForm from '../../RecognitionForm';

// Coins
import SendCoinsForm from '../../SendCoinsForm';

// Constant
import {
  getOccasionList,
  getRecognitionMessage,
  getTagsList,
  getUsersList,
} from '../../../data';
import { randomString } from '../../../utils';
import { CloseOutlined } from '@ant-design/icons';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    props.isDrawer
      ? '10px 0px 0px'
      : props.isReply
      ? '0px 0px 15px 0px'
      : '0px 0px 10px 0px'};
`;

const ReplyWrapper = styled.div`
  height: 30px;
  width: ${(props) =>
    props.isDrawer ? 'calc(100% - 65px)' : 'calc(100% - 58px)'};
  fontsize: 12px;
  background-color: rgb(242, 246, 249);
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 3px 3px 0px 0px;
`;

const Recognition = ({ isDrawer }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [showCoinsForm, setShowCoinsForm] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [occasionList, setOccasionList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [newComments, setNewComments] = useState([]);

  useEffect(() => {
    setUsersList(getUsersList());
    setOccasionList(getOccasionList());
    setMessageList(getRecognitionMessage());
    setTagsList(getTagsList());
  }, []);

  const onSendMessage = (values) => {
    if (selectedMessage) {
      const data = { ...values };
      const { users, message } = data;
      data.parent_id = selectedMessage?.id;
      users.push(selectedMessage?.user);
      data.message = `@${selectedMessage?.user?.name} ${message}`;
      newComments.push({
        ...values,
        id: randomString(),
        user: currentUser,
        mentioned_users: users,
        reaction: {
          users: { 1: [], 2: [], 3: [] },
          count: { 1: 0, 2: 0, 3: 0 },
        },
      });
      setNewComments([...newComments]);
      setSelectedMessage(false);
      return;
    }
    messageList.push({
      ...values,
      id: randomString(),
      user: currentUser,
      reaction: {
        users: { 1: [], 2: [], 3: [] },
        count: { 1: 0, 2: 0, 3: 0 },
      },
    });
    setMessageList([...messageList]);
  };

  const onSendCoins = (values) => {
    // console.log(values);
    messageList.push({
      ...values,
      id: randomString(),
      user: currentUser,
      reaction: {
        users: { 1: [], 2: [], 3: [] },
        count: { 1: 0, 2: 0, 3: 0 },
      },
      receiver: usersList.find((obj) => obj.id == values.receiver),
      occasion: occasionList.find((obj) => obj.id == values.occasion),
      created_at: new Date(),
    });
    setMessageList([...messageList]);
    setShowCoinsForm(false);
  };

  const onReaction = (index, key) => {
    const element = messageList[index];
    const reactions = element?.reaction?.users[key] || [];
    const rIndex = reactions.findIndex((ele) => ele.id === currentUser.id);
    if (rIndex < 0) {
      messageList[index].reaction?.users[key].push(currentUser);
      messageList[index].reaction.count[key] += 1;
    } else {
      messageList[index].reaction?.users[key].splice(rIndex, 1);
      messageList[index].reaction.count[key] -= 1;
    }
    setMessageList([...messageList]);
  };

  return (
    <>
      <RecognitionMessage
        messages={messageList}
        showCoinsForm={showCoinsForm}
        onReaction={(index, key) => onReaction(index, key)}
        isDrawer={isDrawer}
        onSelectMessage={(e) => setSelectedMessage(e)}
        newComments={newComments}
      />
      {selectedMessage && (
        <ReplyWrapper>
          <span>
            Replying to <b>{selectedMessage?.user?.name}</b>
          </span>
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedMessage(null)}
          />
        </ReplyWrapper>
      )}
      <Footer>
        {!showCoinsForm ? (
          <RecognitionForm
            users={usersList.map((obj) => ({ ...obj, value: obj.name }))}
            tags={tagsList}
            onShowForm={() => setShowCoinsForm(true)}
            onSendMessage={onSendMessage}
            selectedMessage={selectedMessage}
            occasion={occasionList}
            submitLoading={false}
          />
        ) : (
          <SendCoinsForm
            users={usersList}
            occasion={occasionList}
            onShowForm={() => setShowCoinsForm(false)}
            onSendCoins={onSendCoins}
          />
        )}
      </Footer>
    </>
  );
};

Recognition.propTypes = {
  isDrawer: PropTypes.bool,
};

export default Recognition;
