import React from 'react';
import PropTypes from 'prop-types';

import RecognitionWrapper from './RecognitionWrapper';
import { RecognitionProvider } from './providers';

const Recognition = ({ isDrawer }) => {
  return (
    <RecognitionProvider>
      <RecognitionWrapper isDrawer={isDrawer} />
    </RecognitionProvider>
  );
};

Recognition.propTypes = {
  isDrawer: PropTypes.bool,
};

export default Recognition;
