import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  background: #f2f6f9;
  border-radius: 4px;
  border: none;
  height: 40px;

  &&&.ant-input-status-error {
    border: 1px solid #ff4d4f;
  }
`;

const InputNumber = ({ value = {}, onChange }) => {
  const [number] = useState(0);

  const triggerChange = (changedValue) => {
    onChange?.({
      number,
      ...value,
      ...changedValue,
    });
  };

  const onNumberChange = (e) => {
    const newNumber = parseInt(e.target.value || '0', 10);
    if (Number.isNaN(number)) {
      return;
    }
    triggerChange({
      number: newNumber,
    });
  };

  return (
    <StyledInput value={value.number || number} onChange={onNumberChange} />
  );
};

InputNumber.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};
export default InputNumber;
