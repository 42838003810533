import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import RecognitionMessage from './Message';
import RecognitionForm from '../RecognitionForm';
import SendCoinsForm from '../SendCoinsForm';

// Icons
import { CloseOutlined } from '@ant-design/icons';

// utils
import { useRecognition } from './providers';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    props.isDrawer
      ? '10px 0px 0px'
      : props.isReply
      ? '0px 0px 15px 0px'
      : '15px 0px 10px 0px'};
`;

const ReplyWrapper = styled.div`
  height: 30px;
  width: 85%;
  fontsize: 12px;
  background-color: rgb(242, 246, 249);
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 3px 3px 0px 0px;
`;

const RecognitionWrapper = ({ isDrawer }) => {
  const childRef = useRef();
  const {
    showCoinsForm,
    page,
    usersList,
    occasionList,
    valuesList,
    selectedMessage,
    showMore,
    setSelectedMessage,
    showMessageSubmitLoading,
    setPage,
    setShowCoinsForm,
    getAllOption,
    getMessage,
    onSendMessage,
  } = useRecognition();

  useEffect(() => {
    getAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMessage();
    if (page === 1) {
      setTimeout(() => {
        childRef.current.scrollDown();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onRefresh = () => {
    if (!showMore) return;
    setPage(page + 1);
  };

  const onSubmitMessage = async (values) => {
    try {
      await onSendMessage(values);
    } catch (e) {
      console.log(e);
    } finally {
      if (!selectedMessage) {
        setTimeout(() => {
          childRef.current.scrollDown();
        }, 200);
      }
    }
  };

  return (
    <>
      <RecognitionMessage
        ref={childRef}
        hasMore={showMore}
        fetchData={() => onRefresh()}
        isDrawer={isDrawer}
      />
      {selectedMessage && (
        <ReplyWrapper>
          <span>
            Replying to <b>{selectedMessage?.user?.name}</b>
          </span>
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedMessage(null)}
          />
        </ReplyWrapper>
      )}
      <Footer isDrawer={isDrawer} isReply={selectedMessage}>
        {!showCoinsForm ? (
          <RecognitionForm
            users={usersList.map((obj) => ({ ...obj, value: obj.name }))}
            tags={valuesList}
            occasion={occasionList}
            onShowForm={() => setShowCoinsForm(true)}
            onSendMessage={onSubmitMessage}
            submitLoading={showMessageSubmitLoading}
            selectedMessage={selectedMessage}
          />
        ) : (
          <SendCoinsForm
            users={usersList}
            occasion={occasionList}
            onShowForm={() => setShowCoinsForm(false)}
            onSendCoins={onSendCoins}
          />
        )}
      </Footer>
    </>
  );
};

RecognitionWrapper.propTypes = {
  isDrawer: PropTypes.bool,
};

export default RecognitionWrapper;
