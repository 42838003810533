import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';

// constant
import { API_URL } from '../../constant';

const RecognitionContext = createContext();

export function RecognitionProvider({ children }) {
  const { currentUser } = useSelector((state) => state.user);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [messageLoading, setMessageLoading] = useState(true);
  const [showCoinsForm, setShowCoinsForm] = useState(false);
  const [showMessageSubmitLoading, setShowMessageSubmitLoading] =
    useState(false);
  const [page, setPage] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [occasionList, setOccasionList] = useState([]);
  const [valuesList, setValuesList] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const fetchComments = async (parentId, page) => {
    try {
      const response = await axios.get(
        `${API_URL}/recognition/reply?parent_id=${parentId}&page=${page}`,
      );
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const getMessage = async () => {
    setMessageLoading(true);
    try {
      const { results, next } = await axios.get(
        `${API_URL}/recognition/?page=${page}`,
      );
      setShowMore(!!next);
      setMessageList([...results, ...messageList]);
    } catch (error) {
      setMessageList([]);
    } finally {
      setMessageLoading(false);
    }
  };

  const getAllOption = async () => {
    try {
      const { users, values, occasion, attributes } = await axios.get(
        `${API_URL}/recognition/options`,
      );
      setUsersList(users);
      setValuesList(values.map((ele) => ({ ...ele, value: ele.name })) || []);
      setOccasionList([
        ...attributes.map((ele) => ({
          ...ele,
          value: ele.name,
          label: ele.name,
        })),
        ...occasion.map((ele) => ({ ...ele, value: ele.name })),
      ]);
    } catch {
      setUsersList([]);
      setValuesList([]);
      setOccasionList([]);
    }
  };

  const onSendMessage = async (values) => {
    setShowMessageSubmitLoading(true);
    try {
      const data = { ...values };
      const { users } = data;
      if (selectedMessage) {
        data.parent_id = selectedMessage?.id;
        users.push(selectedMessage?.user);
        // data.message = `@${selectedMessage?.user?.name} ${message}`;
      }
      if (data?.occasion?.length) {
        const selectedOccasion = [...data.occasion];
        const companyAttributeIds = currentUser?.company?.attributes.map(
          (ele) => ele.id,
        );
        data.attributes = selectedOccasion
          .filter((obj) => companyAttributeIds.includes(obj.id))
          .map((obj) => obj.id);
        data.occasion = selectedOccasion
          .filter((obj) => !companyAttributeIds.includes(obj.id))
          .map((obj) => obj.id);
      }
      data.users = users ? users.map((obj) => obj.id) : [];
      data.values = data.values ? data.values.map((obj) => obj.id) : [];
      const response = await axios.post(`${API_URL}/recognition/`, data);
      if (selectedMessage) {
        setSelectedMessage(null);
      } else {
        // messageList.push(response);
        setMessageList([...messageList, response]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowMessageSubmitLoading(false);
    }
  };

  const onReaction = async (index, key) => {
    const element = messageList[index];
    const reactions = element?.reaction?.users[key] || [];
    const rIndex = reactions.findIndex((ele) => ele.id === currentUser.id);
    if (rIndex < 0) {
      messageList[index].reaction?.users[key].push(currentUser);
      messageList[index].reaction.count[key] += 1;
    } else {
      messageList[index].reaction?.users[key].splice(rIndex, 1);
      messageList[index].reaction.count[key] -= 1;
    }
    setMessageList([...messageList]);
    try {
      const data = {
        recognition_id: element.id,
        reaction_type: key,
      };
      await axios.post(`${API_URL}/recognition/reaction`, data);
    } catch (e) {
      console.error(e);
    }
  };

  const onSendCoins = async (values) => {
    // console.log(values);
    messageList.push({
      ...values,
      id: randomString(),
      user: currentUser,
      reaction: {
        users: { 1: [], 2: [], 3: [] },
        count: { 1: 0, 2: 0, 3: 0 },
      },
      receiver: usersList.find((obj) => obj.id == values.receiver),
      occasion: occasionList.find((obj) => obj.id == values.occasion),
      created_at: new Date(),
    });
    setMessageList([...messageList]);
    setShowCoinsForm(false);
  };

  const value = {
    selectedMessage,
    messageList,
    messageLoading,
    showCoinsForm,
    page,
    usersList,
    occasionList,
    valuesList,
    showMessageSubmitLoading,
    showMore,
    setShowMore,
    setShowMessageSubmitLoading,
    setPage,
    setUsersList,
    setOccasionList,
    setValuesList,
    setShowCoinsForm,
    setMessageLoading,
    setMessageList,
    setSelectedMessage,
    getMessage,
    getAllOption,
    fetchComments,
    onSendMessage,
    onSendCoins,
    onReaction,
  };

  return (
    <RecognitionContext.Provider value={value}>
      {children}
    </RecognitionContext.Provider>
  );
}

RecognitionProvider.propTypes = {
  children: PropTypes.node,
};

export function useRecognition() {
  const context = useContext(RecognitionContext);
  if (context === undefined) {
    throw new Error('useRecognition must be used within a RecognitionProvider');
  }
  return context;
}
