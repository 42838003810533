import axios from 'axios';
import { TOKEN_KEY } from './constant';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY) || null;
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
});

axios.interceptors.response.use((res) => {
  return res.data;
});
