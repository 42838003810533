export const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8080/api/v1';

export const APP_ENV = process.env.REACT_APP_ENV;

export const TOKEN_KEY = 'nasch-token';
export const SLUG_KEY = 'nasch-slug';
export const TOUR_KEY = 'nasch-tour';

export const PARTNER_URL = process.env.REACT_APP_PARTNER_URL;
export const DEFAULT_CHOICE_NUDGE_COINS = 100;
export const DEFAULT_TEXT_NUDGE_COINS = 2; // per word
export const DEFAULT_CHOICE_ANSWER_COINS = '02';
export const DEFAULT_ACTIVE_SEGMENT = Number(
  process.env.REACT_APP_DEFAULT_ACTIVE_SEGMENT || 0,
);

export const DEFAULT_ENVIRONMENT = {
  DEMO: 'demo',
  STAGE: 'staging',
  PROD: 'production',
  DEV: 'development',
};

export const DEFAULT_QUESTION_TYPES = {
  MULTIPLE_CHOICE: 0,
  CHOICE: 1,
  INPUT: 2,
  RATING: 3,
  SLIDER: 4,
  EMOJI: 5,
  SLIDER_RATING: 6,
  SLIDER_AGREE_DISAGREE_RATING: 7,
  BEHAVIOUR: 8,
};

export const REACTION_TYPE = {
  1: '👍️',
  2: '❤️',
  3: '😃',
};

export const DEFAULT_MAXIMUM_SLIDER_VALUE = 10;
export const DEFAULT_MINIMUM_SLIDER_VALUE = 0;

export const DEFAULT_MAXIMUM_SLIDER_RATING_VALUE = 5;
export const DEFAULT_MINIMUM_SLIDER_RATING_VALUE = 1;

export const DEFAULT_PAGE_SIZE = 5;

export const DEFAULT_PAGE_SIZE_LIST = [5, 10, 15, 20];

export const HRMS_TYPE = {
  OPPORTUNE: '1',
};

export const DEFAULT_LOGO = process.env.REACT_APP_DEFAULT_LOGO || null;

export const DEFAULT_LOGO_HEIGHT =
  process.env.REACT_APP_DEFAULT_LOGO_HEIGHT || 50;

export const DEFAULT_QUESTION_ANONYMITY = Boolean(
  process.env.REACT_APP_DEFAULT_QUESTION_ANONYMITY || 0,
);

export const BACKY_ICON = process.env.REACT_APP_BACKY_ICON || null;

export const HIDE_SUGGESTION_BOX_ROLE = Boolean(
  Number(process.env.REACT_APP_HIDE_SUGGESTION_BOX_ROLE || 0),
);

export const DEFAULT_SUGGESTION_BOX_ANONYMITY = Boolean(
  Number(process.env.REACT_APP_DEFAULT_SUGGESTION_BOX_ANONYMITY || 1),
);

export const TagColors = [
  {},
  {
    backgroundColor: '#F1EAFF',
    color: '#7743DB',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#e4fff6',
    color: '#376455',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FFF6F6',
    color: '#F875AA',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F5ECFF',
    color: '#47335D',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#EDF7FC',
    color: '#3DA6DF',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#fff8dc',
    color: '#8b6914',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E5F5E5',
    color: '#88AB8E',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F7E4EF',
    color: '#47335D',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#f1f8f1',
    color: '#315B31',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#f5f8fa',
    color: '#164B6E',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FBF9F1',
    color: '#846C0D',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F5EEE6',
    color: '#E6A4B4',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F2F1EB',
    color: '#88AB8E',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E8EAF8',
    color: '#47335D',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#dcf0fa',
    color: '#3DA6DF',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#EBF3E8',
    color: '#86A789',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#e3ffd7',
    color: '#376455',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#fafafa',
    color: '#484b6a',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#fffee0',
    color: '#8b6914',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FFF4E9',
    color: '#f08080',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F7F8E5',
    color: '#696C2A',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#abdbe3',
    color: '#063970',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#C5ECE8',
    color: '#71D089',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F1E4D4',
    color: '#7a6913',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#EECCE2',
    color: '#DC95C9',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F6E5EA',
    color: '#D68599',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E9DBF3',
    color: '#B38CD9',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FEF2E6',
    color: '#816e0d',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F6F1F1',
    color: '#CDACBB',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#D6D4E5',
    color: '#8980B3',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#EBF3F0',
    color: '#A8CBC1',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F2F5F7',
    color: '#88B7B4',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F1E4D4',
    color: '#7674AB',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F6F1F4',
    color: '#CBA8C1',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E3DFD1',
    color: '#AC9974',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F8F4F3',
    color: '#D3C1B6',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#C0D5B8',
    color: '#7E9056',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E2EEE6',
    color: '#8DBA9C',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#CCE1D1',
    color: '#4E683E',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F8F3F3',
    color: '#BF9496',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F5EEF5',
    color: '#735D9B',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F6F7FA',
    color: '#9C7ED4',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F6FCF6',
    color: '#71D099',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F1FAF0',
    color: '#30914F',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FAF8F0',
    color: '#8E7A2F',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#F4FBFA',
    color: '#37A59A',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#FFE5FF',
    color: '#431C54',
    border: 'none',
    marginBottom: '3px',
  },
  {
    backgroundColor: '#E2FEDC',
    color: '#ADFA8B',
    border: 'none',
    marginBottom: '3px',
  },
];
