export const randomString = () => {
  return Math.random().toString(26).substr(3, 8);
};

export const getShortName = (name) => {
  if (!name) return '';
  const [fName, lName] = name.split(' ');
  return `${fName[0]}${lName?.length > 0 ? lName[0] : ''}`;
};

/** Function to generate unique color from a given string */

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const stringToColor = (string) => {
  return HSLtoString(generateHSL(string));
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

/** End of Function to generate unique color from a given string */
