import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'antd';
import moment from 'moment';

import { getShortName, stringToColor } from '../../utils';
import { useSelector } from 'react-redux';
import { colorPalette } from '../../theme';

const MessageChannel = styled.div`
  height: ${(props) =>
    props.isDrawer
      ? 'calc(100vh - 180px)'
      : 'calc(100vh - (72px + 35px + 24px + 65px + 62px + 25px))'};
  background-color: #fff;
  overflow: auto;
  border-bottom: 1px solid #e2e3e3;
`;

const ReceiverWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.margin || '20px'};
`;

const Sender = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.margin || '20px'};
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props) =>
    props.name ? stringToColor(props.name) : '#841f8f'};
  font-size: 18px !important;
`;

const Text = styled.div`
  padding: 11px 20px;
  font-size: 14px;
  line-height: 17px;
  line-height: 17px;
  white-space: pre-wrap;
  border-radius: 4px;
`;

const SenderText = styled(Text)`
  color: #212121;
  background: #f2f6f9;
  max-width: ${(props) => (props.isDrawer ? '58%' : '65%')};
  // margin-left: ${(props) => (props.isDrawer ? '10px' : '15px')};
  white-space: pre-wrap;
  p {
    margin: 0px;
  }
  span {
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

const ReceiverText = styled(Text)`
  color: #fff;
  background: ${colorPalette.primary[500]};
  max-width: ${(props) => (props.isDrawer ? '58%' : '65%')};
  // margin-left: ${(props) => (props.isDrawer ? '10px' : '15px')};
  // margin-right: 10px;
  white-space: pre-wrap;

  p {
    margin: 0px;
  }

  span {
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

const DateWrapper = styled.div`
  display: block;
  padding-bottom: 30px;

  p {
    text-align: center;
    border-bottom: 1px solid #e2e3e3;
    position: relative;
    margin: 0px;
  }

  span {
    background-color: white;
    position: relative;
    top: 10px;
    padding: 0 10px;
    font-size: 14px;
    color: #617080;
  }
`;

const Message = ({ isDrawer, messages, isAnonymous, jobRole = {} }) => {
  const { currentUser } = useSelector((state) => state.user);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getReceiverAvatarText = (name) => {
    if (!name) return;
    return name.split(' ')[0];
  };

  const getTime = (date) => {
    return moment(date).format('LT');
  };

  return (
    <MessageChannel isDrawer={isDrawer}>
      {Object.entries(messages).map(([date, element]) => (
        <Fragment key={date}>
          <DateWrapper>
            <p>
              <span>{date}</span>
            </p>
          </DateWrapper>
          {element.map((message) => {
            if (message.sent_by === 'ADM') {
              return (
                <Sender
                  key={message.id}
                  margin={message?.sent_by === 'ADM' ? '10px' : '20px'}>
                  {!isAnonymous && (
                    <StyledAvatar name={jobRole?.label} size="large">
                      {getReceiverAvatarText(jobRole?.label)}
                    </StyledAvatar>
                  )}
                  <SenderText isDrawer={isDrawer}>
                    <p>{message.text}</p>
                    <span>{getTime(message.created_at)}</span>
                  </SenderText>
                </Sender>
              );
            }
            return (
              <ReceiverWrapper
                key={message.id}
                margin={message?.sent_by !== 'ADM' ? '10px' : '20px'}>
                <ReceiverText isDrawer={isDrawer}>
                  <p>{message.text}</p>
                  <span>{getTime(message.created_at)}</span>
                </ReceiverText>
                {!isAnonymous && (
                  <StyledAvatar name={currentUser?.name} size="large">
                    {getShortName(currentUser?.name)}
                  </StyledAvatar>
                )}
              </ReceiverWrapper>
            );
          })}
        </Fragment>
      ))}
      <div ref={messagesEndRef} />
    </MessageChannel>
  );
};

Message.propTypes = {
  messages: PropTypes.object,
  isAnonymous: PropTypes.bool,
  isDrawer: PropTypes.bool,
  jobRole: PropTypes.object,
};

export default Message;
