import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button, Col, Input, Modal, Row, Select, Switch, Tour } from 'antd';
import Message from './Message';
import FullPageLoader from '../FullPageLoader';

// icons
import {
  CloseOutlined,
  ExclamationCircleFilled,
  LockOutlined,
  SendOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

// utils
import { translate } from '../../i18n/i18n';
import { randomString } from '../../utils';
import useInterval from '../../hooks/interval';
import {
  onSetSuggestionBoxMessage,
  onShowSuggestionBoxTour,
} from '../../store/user';

// constant
import {
  API_URL,
  DEFAULT_SUGGESTION_BOX_ANONYMITY,
  HIDE_SUGGESTION_BOX_ROLE,
} from '../../constant';

const { TextArea } = Input;
const { confirm: confirmationModal } = Modal;

const t = translate(['common', 'suggestionBox', 'tour']);

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 4px;
  }
`;

const StyledRow = styled(Row)`
  align-items: center;
  margin-bottom: 15px;
`;

const RightCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AnonymousText = styled.span`
  font-size: 12px;
  line-height: 10px;
  color: #617080;
  margin-right: 8px;
`;

const StyledLockIcon = styled(LockOutlined)`
  font-size: 14px;
  margin: 0px 5px;
`;

const StyledUnLockIcon = styled(UnlockOutlined)`
  font-size: 14px;
  margin: 0px 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isDrawer ? '10px 0px 0px' : '6px 0px')};
`;

const StyledInput = styled(TextArea)`
  &.ant-input {
    background: #f2f6f9;
    border: 1px solid #f2f6f9;
    border-radius: 2px;
    padding: 10px;
    max-height: 44px;
  }
`;

const StyledButton = styled(Button)`
  &&&.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 21px 23px;
    // color: #fff;
    // background-color: #1677ff;
    margin-left: 10px;
  }
`;

const SuggestionBox = ({ isDrawer }) => {
  const dispatch = useDispatch();
  const { loginLoading, showSuggestionBoxTour } = useSelector(
    (state) => state.user,
  );

  const fullRef = useRef(null);
  const closeButtonRef = useRef(null);
  const anonymityRef = useRef(null);
  const jobRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isAnonymous, setIsAnonymous] = useState(
    DEFAULT_SUGGESTION_BOX_ANONYMITY,
  );
  const [jobRoleLoading, setJobRoleLoading] = useState(true);
  const [selectedJobRole, setSelectedJobRole] = useState('');
  const [jobRoleOptions, setJobRoleOptions] = useState([
    { label: 'HR', value: '' },
    { label: 'CEO', value: 1 },
  ]);
  const [input, setInput] = useState('');
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [messageList, setMessageList] = useState({});
  const [tourSteps, setTourSteps] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagLoading, setTagLoading] = useState(true);

  useEffect(() => {
    setTourSteps([
      {
        title: t('suggestionBox.box.title'),
        description: t('suggestionBox.box.description'),
        target: () => fullRef.current,
      },
      {
        title: t('suggestionBox.jobRole.title'),
        description: t('suggestionBox.jobRole.description'),
        target: () => jobRef.current,
      },
      {
        title: t('suggestionBox.anonymous.title'),
        description: t('suggestionBox.anonymous.description'),
        target: () => anonymityRef.current,
      },
      {
        title: t('suggestionBox.closeButton.title'),
        description: t('suggestionBox.closeButton.description'),
        target: () => closeButtonRef.current,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loginLoading) {
      getJobRole();
      getFeedback();
      getTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginLoading]);

  useInterval(() => {
    if (Object.values(messageList)[0].length > 1) {
      getFeedback();
    }
  }, 1000);

  const getDate = (date) => {
    return moment(date).format('DD MMMM, YYYY');
  };

  const formatMessageList = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const setDefaultMessages = () => {
    const data = [
      {
        id: 0,
        sent_by: 'ADM',
        text: 'Hey 👋, how can we help you?',
        created_at: new Date(),
        date: getDate(new Date()),
      },
    ];
    setMessageList(formatMessageList(data, 'date'));
    dispatch(onSetSuggestionBoxMessage(false));
  };

  const updateMessage = (messages) => {
    messages.unshift({
      id: randomString(),
      sent_by: 'ADM',
      text: 'Hey 👋, how can we help you?',
      created_at: messages[0].created_at,
      date: messages[0].created_at,
    });
    setMessageList(
      formatMessageList(
        messages.map((ele) => ({
          ...ele,
          date: getDate(ele.created_at),
        })),
        'date',
      ),
    );
    dispatch(onSetSuggestionBoxMessage(true));
  };

  const getFeedback = async () => {
    // setLoading(true);
    try {
      const { message_list, is_anonymous, job_role, channel } = await axios.get(
        `${API_URL}/survey/user-feedback/`,
      );
      if (!message_list.length) {
        setDefaultMessages();
        onSetSuggestionBoxMessage(false);
      } else {
        setSelectedJobRole(Number(job_role) || '');
        setIsAnonymous(is_anonymous);
        updateMessage(message_list);
        setSelectedTags([...new Set([...channel, ...selectedTags])]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getJobRole = async () => {
    if (HIDE_SUGGESTION_BOX_ROLE) {
      setJobRoleOptions([{ label: 'HR', value: '' }]);
      setJobRoleLoading(false);
      return;
    }
    setJobRoleLoading(true);
    try {
      const response = await axios.get(`${API_URL}/accounts/job-role/`);
      const { ceo, hod, manager } = response;
      const options = [
        { label: 'All', value: '' },
        { label: 'CEO', value: 1 },
      ];
      if (ceo) {
        options[1].label = `CEO (${ceo}) `;
      }
      if (hod) {
        options.push({
          label: `Head of Department (${hod})`,
          value: 3,
        });
      }
      if (manager) {
        options.push({
          label: `Manager (${manager})`,
          value: 4,
        });
      }
      setJobRoleOptions([...options]);
    } catch (e) {
      console.log(e);
      setJobRoleOptions([]);
    } finally {
      setJobRoleLoading(false);
    }
  };

  const getTags = async () => {
    setTagLoading(true);
    try {
      const response = await axios.get(`${API_URL}/survey/feedback-channels/`);
      setTagsList(response);
    } catch {
      setTagsList([]);
    } finally {
      setTagLoading(false);
    }
  };

  const onSubmit = async () => {
    setShowSubmitLoader(true);
    try {
      const body = {
        text: input,
        job_role: selectedJobRole || null,
        channel: selectedTags,
      };
      const { message_list, is_anonymous, job_role } = await axios.post(
        `${API_URL}/survey/user-feedback/`,
        body,
      );
      setSelectedJobRole(Number(job_role) || '');
      setIsAnonymous(is_anonymous);
      updateMessage(message_list);
      setInput('');
    } catch {
    } finally {
      setShowSubmitLoader(false);
    }
  };

  const sendRevealRequest = () => {
    axios.put(`${API_URL}/survey/user-feedback/`);
    setIsAnonymous(false);
  };

  const showClearConfirmation = () => {
    confirmationModal({
      title: t('closeConversation.title'),
      icon: <ExclamationCircleFilled />,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('closeConversation.description'),
          }}
        />
      ),
      okText: t('closeConversation.okText'),
      okType: 'danger',
      cancelText: t('closeConversation.cancelText'),
      centered: true,
      onOk() {
        onClear();
      },
      onCancel() {},
    });
  };

  const onClear = async () => {
    setDefaultMessages();
    setInput('');
    setSelectedJobRole('');
    setIsAnonymous(true);
    onSetSuggestionBoxMessage(false);
    axios.delete(`${API_URL}/survey/user-feedback/`);
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <div ref={fullRef}>
      <StyledRow gutter={[15, 10]}>
        <Col span={16} ref={jobRef}>
          <StyledSelect
            placeholder="Who should see your message?"
            options={jobRoleOptions}
            value={selectedJobRole}
            onChange={(e) => setSelectedJobRole(e)}
            loading={jobRoleLoading}
            disabled={!isAnonymous}
          />
        </Col>
        <RightCol span={8} ref={anonymityRef}>
          {isAnonymous ? <StyledUnLockIcon /> : <StyledLockIcon />}
          <AnonymousText className="anonymous-text">
            {isAnonymous ? t('anonymous') : t('anonymity')}
          </AnonymousText>
          <Switch
            size="small"
            checked={!isAnonymous}
            disabled={!isAnonymous}
            onChange={() =>
              confirmationModal({
                title: t('revealConversation.title'),
                icon: <ExclamationCircleFilled />,
                content: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('revealConversation.description'),
                    }}
                  />
                ),
                okText: t('revealConversation.confirmText'),
                okType: 'primary',
                cancelText: t('revealConversation.cancelText'),
                centered: true,
                onOk() {
                  sendRevealRequest();
                },
                onCancel() {},
              })
            }
          />
        </RightCol>
      </StyledRow>
      <Col span={24}>
        <StyledSelect
          mode="multiple"
          placeholder="Select Channel"
          options={tagsList.map((item) => ({
            value: item.id,
            label: item.title,
          }))}
          optionFilterProp="label"
          value={selectedTags}
          onChange={(e) => setSelectedTags(e)}
          loading={tagLoading}
        />
      </Col>
      <Message
        jobRole={jobRoleOptions.find((ele) => ele.value === selectedJobRole)}
        messages={messageList}
        isAnonymous={isAnonymous}
        isDrawer={isDrawer}
      />
      <Footer isDrawer={isDrawer}>
        <StyledInput
          autoSize={true}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <StyledButton
          disabled={!input}
          onClick={onSubmit}
          type={'primary'}
          loading={showSubmitLoader}
          icon={<SendOutlined />}
          size="large"
        />
        <StyledButton
          danger
          type="primary"
          disabled={Object.values(messageList)[0].length === 1}
          icon={<CloseOutlined />}
          size="large"
          onClick={showClearConfirmation}
          ref={closeButtonRef}
        />
      </Footer>
      <Tour
        open={showSuggestionBoxTour}
        steps={tourSteps}
        onClose={() => {
          dispatch(onShowSuggestionBoxTour(false));
        }}
      />
    </div>
  );
};

SuggestionBox.propTypes = {
  isDrawer: PropTypes.bool,
};

export default SuggestionBox;
