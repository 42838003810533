import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Component
import { Avatar, Tag, Tooltip } from 'antd';
import Comments from './Comments';

// Constant
import { REACTION_TYPE } from '../../constant';

// utils
import { getShortName, stringToColor } from '../../utils';
import { useRecognition } from './providers';
import { CommentOutlined } from '@ant-design/icons';
import { colorPalette } from '../../theme';

const MessageWrapper = styled.div`
  // display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e3e3;
  margin-top: 15px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props) =>
    props.name ? stringToColor(props.name) : '#841f8f'};
  font-size: 18px !important;
`;

const UserProfile = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDiv = styled.div`
  margin-left: 10px;
`;

const UserName = styled.p`
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
  color: #212121;
`;

const StyledMessage = styled.div`
  margin-left: 3rem;
`;

const MessageText = styled.div`
  border-left: 3px solid #37a19d;

  p {
    margin-left: 12px;
    white-space: pre-wrap;
  }
`;

// const ValuesText = styled.span`
//   margin: 0px 10px;
// `;

const ValueWrapper = styled.div`
  border-left: 3px solid #f0cc76;

  .ant-tag {
    text-transform: uppercase;
    border: none;
    font-size: 12px;
    color: #212121;

    :first-child {
      margin-left: 10px;
    }
  }
`;

const EmojiWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  .ant-tag {
    cursor: pointer;
    background: #f5f5f5;
    border-radius: 20px;

    &.active {
      border: 1px solid ${colorPalette.primary[500]};
      background: #ecf5fe;
      color: ${colorPalette.primary[500]};
      font-size: 13px;
    }
  }
`;

const StyledTag = styled(Tag)`
  background-color: ${(props) => (props.type === 'A' ? '#d5e7fa' : '#dcfae7')};
  border-radius: 20px;
`;

const TooltipIcon = styled.span`
  font-size: 24px;
  display: block;
  text-align: center;
`;

const TooltipText = styled.div`
  font-weight: 500;
  small {
    font-weight: 300;
    color: #ecf5fe;
  }
`;

const StyledCoin = styled.img`
  background: #ffac33;
  padding: 2px;
  height: 20px;
`;

const ReplyText = styled.span`
  cursor: pointer;
`;

const DateText = styled.small`
  font-size: 12px;
  line-height: 14px;
  color: #617080;
`;

const StyledCommentOutlined = styled(CommentOutlined)`
  font-size: 20px;
`;

const MessageTile = ({ element, index }) => {
  const { currentUser } = useSelector((state) => state.user);
  const { setSelectedMessage, onReaction } = useRecognition();

  const [showComments, setShowComments] = useState(false);

  const getTooltipText = (users) => {
    const user = users.map((ele) => ele.name);
    const last = user.pop();
    return user.length
      ? `${user.join(', ')} <small>and</small> ${last} <small>reacted</small>.`
      : `${last} <small>reacted<small>.`;
  };

  const isReacted = (users, key) => {
    return (users[key] || []).find((ele) => ele.id === currentUser.id)
      ? true
      : false;
  };

  const formatMessage = (string, usersArray, tagsArray) => {
    usersArray.forEach((user) => {
      const nameRegex = new RegExp(`@${user.name}`, 'g');
      if (string.match(nameRegex)) {
        string = string.replace(nameRegex, `<b>${user.name}</b>`);
      }
    });

    tagsArray.forEach((tag) => {
      const tagRegex = new RegExp(`(?:\\s|^)[\\/#]${tag.name}\\b`, 'g');
      string = string.replace(tagRegex, '');
    });

    return string;
  };

  const getMessageText = (element) => {
    if (element?.coins > 1) {
      return (
        <p>
          sent <StyledCoin src={CoinsIcon} alt="coin" /> {element?.coins} to{' '}
          <b>{element?.receiver?.name}</b> on their{' '}
          <b>{element.occasion?.name}</b> with the following message :
          <br />
          <i>{element.message}</i>
        </p>
      );
    }
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            element?.message,
            element.mentioned_users,
            element.tags,
          ),
        }}
      />
    );
  };

  const getEmojiText = (key, value) => {
    const tagElement = (
      <Tag
        key={key}
        onClick={() => onReaction(index, key)}
        className={isReacted(element?.reaction?.users, key) && 'active'}>
        {value}{' '}
        {element?.reaction?.count[key] > 0 && element?.reaction?.count[key]}
      </Tag>
    );

    if (element?.reaction?.count[key] > 0) {
      return (
        <Tooltip
          key={key}
          placement="top"
          title={
            <>
              <TooltipIcon>{value}</TooltipIcon>
              <TooltipText
                dangerouslySetInnerHTML={{
                  __html: getTooltipText(element?.reaction?.users[key]),
                }}
              />
            </>
          }>
          {tagElement}
        </Tooltip>
      );
    }
    return tagElement;
  };

  return (
    <>
      <MessageWrapper>
        <TitleWrapper>
          <UserProfile>
            <StyledAvatar name={element?.user?.name} size="large">
              {getShortName(element?.user?.name)}
            </StyledAvatar>
            <StyledDiv>
              <UserName>{element?.user?.name}</UserName>
              <small>{element?.user?.job_title || element?.user?.email}</small>
            </StyledDiv>
          </UserProfile>
          <DateText>{moment(element.created_at).fromNow()}</DateText>
        </TitleWrapper>
        <StyledMessage>
          <MessageText>{getMessageText(element)}</MessageText>
          {element?.tags?.length > 0 && (
            <ValueWrapper>
              {/* <ValuesText>Values:</ValuesText> */}
              {element?.tags?.map((tag) => (
                <StyledTag type={tag?.type} key={tag?.id}>
                  {tag?.name}
                </StyledTag>
              ))}
            </ValueWrapper>
          )}
          <EmojiWrapper style={showComments ? { marginBottom: '15px' } : {}}>
            <div>
              {Object.entries(REACTION_TYPE).map(([key, value]) =>
                getEmojiText(key, value),
              )}
            </div>
            <ReplyText
              onClick={() => {
                const flag = showComments;
                setShowComments(!showComments);
                setSelectedMessage(!flag ? element : null);
              }}>
              <StyledCommentOutlined />
              {/* {element?.total_reply >= 1 ? t('replies') : t('reply')} */}
            </ReplyText>
          </EmojiWrapper>
          {showComments && <Comments parentId={element?.id} />}
        </StyledMessage>
      </MessageWrapper>
    </>
  );
};

MessageTile.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
};

export default MessageTile;
