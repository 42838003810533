import { createSlice } from '@reduxjs/toolkit';
import { APP_ENV, DEFAULT_ENVIRONMENT } from '../constant';
import { getDefaultUser } from '../data';

const getInitialState = () => {
  if (APP_ENV === DEFAULT_ENVIRONMENT.DEMO) {
    return {
      currentUser: getDefaultUser(),
      isLoading: false,
      showHeaderLoading: false,
      showSuggestionBoxTour: false,
    };
  }
  return {
    currentUser: null,
    isLoading: true,
    showHeaderLoading: true,
    loginLoading: true,
    hasSuggestionBoxMessage: false,
    showSuggestionBoxTour: false,
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    onSetLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    onSetUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    onShowHeaderLoading: (state, { payload = false }) => {
      state.showHeaderLoading = payload;
    },
    onSetLoginLoading: (state, { payload }) => {
      state.loginLoading = payload;
    },
    onSetSuggestionBoxMessage: (state, { payload }) => {
      state.hasSuggestionBoxMessage = payload;
    },
    onShowSuggestionBoxTour: (state, { payload }) => {
      state.showSuggestionBoxTour = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onSetLoading,
  onSetUser,
  onShowHeaderLoading,
  onSetLoginLoading,
  onSetSuggestionBoxMessage,
  onShowSuggestionBoxTour,
} = userSlice.actions;

export default userSlice.reducer;
