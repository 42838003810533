import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Button, Col, Input, Modal, Row, Select, Switch } from 'antd';
import Message from './Message';

// Icons
import {
  CloseOutlined,
  ExclamationCircleFilled,
  LockOutlined,
  SendOutlined,
} from '@ant-design/icons';

// utils
import { translate } from '../../../i18n/i18n';
import { randomString } from '../../../utils';

const { TextArea } = Input;
const { confirm: confirmationModal } = Modal;

const t = translate(['common', 'suggestionBox']);

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 4px;
  }
`;

const StyledRow = styled(Row)`
  align-items: center;
  margin-bottom: 15px;
`;

const RightCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AnonymousText = styled.span`
  font-size: 12px;
  line-height: 10px;
  color: #617080;
  margin-right: 8px;
`;

const StyledLockIcon = styled(LockOutlined)`
  font-size: 14px;
  margin: 0px 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isDrawer ? '10px 0px 0px' : '6px 0px')};
`;

const StyledInput = styled(TextArea)`
  &.ant-input {
    background: #f2f6f9;
    border: 1px solid #f2f6f9;
    border-radius: 2px;
    padding: 10px;
    max-height: 44px;
  }
`;

const StyledButton = styled(Button)`
  &&&.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 21px 23px;
    // color: #fff;
    // background-color: #1677ff;
    margin-left: 10px;
  }
`;

const SuggestionBox = ({ isDrawer }) => {
  const options = [
    { label: 'All', value: '' },
    { label: 'CEO (Akshay)', value: 'CEO' },
    { label: 'HR (Nina)', value: 'HR' },
    { label: 'HOD (Akshay Dipali)', value: 'HOD' },
  ];
  const tagsList = [
    { label: 'Ideas', value: 'Ideas' },
    { label: 'Grievance', value: 'Grievance' },
    { label: 'Happy Moment', value: 'Happy Moment' },
    { label: 'Complaints', value: 'Complaints' },
    { label: 'Acknowledgment', value: 'Acknowledgment' },
  ];
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [selectedJobRole, setSelectedJobRole] = useState('');
  const [input, setInput] = useState('');
  const [messageList, setMessageList] = useState([
    {
      date: new Date(),
      message: [
        {
          id: randomString(),
          sent_by: 'ADM',
          text: 'Hey 👋, how can we help you?',
          created_at: new Date(),
          user: { name: 'Nina Cruise' },
        },
      ],
    },
  ]);

  const onSubmit = async () => {
    messageList[0].message.push({
      id: randomString(),
      sent_by: 'USR',
      text: input,
      created_at: new Date(),
      user: { name: 'Akshay Dipali' },
    });
    setMessageList([...messageList]);
    setInput('');
    setTimeout(() => {
      messageList[0].message.push({
        id: randomString(),
        sent_by: 'ADM',
        text: `Oh wow! That's a great idea. Let me talk to the management 😊`,
        created_at: new Date(),
      });
      setMessageList([...messageList]);
    }, 2000);
  };

  const showClearConfirmation = () => {
    confirmationModal({
      title: t('closeConversation.title'),
      icon: <ExclamationCircleFilled />,
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('closeConversation.description'),
          }}
        />
      ),
      okText: t('closeConversation.okText'),
      okType: 'danger',
      cancelText: t('closeConversation.cancelText'),
      centered: true,
      onOk() {
        onClear();
      },
      onCancel() {},
    });
  };

  const onClear = () => {
    setIsAnonymous(true);
    setSelectedJobRole('');
    setMessageList([
      {
        date: new Date(),
        message: [
          {
            id: randomString(),
            sent_by: 'ADM',
            text: 'Hey 👋, how can we help you?',
            created_at: new Date(),
            user: { name: 'Nina Cruise' },
          },
        ],
      },
    ]);
  };

  return (
    <>
      <StyledRow>
        <Col span={16}>
          <StyledSelect
            placeholder="Who should see your message?"
            options={options}
            disabled={!isAnonymous}
            value={selectedJobRole}
            onChange={(e) => setSelectedJobRole(e)}
          />
        </Col>
        <RightCol span={8}>
          <StyledLockIcon />
          <AnonymousText className="anonymous-text">
            {t('anonymous')}
          </AnonymousText>
          <Switch
            size="small"
            checked={!isAnonymous}
            disabled={!isAnonymous}
            onChange={() =>
              confirmationModal({
                title: t('revealConversation.title'),
                icon: <ExclamationCircleFilled />,
                content: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('revealConversation.description'),
                    }}
                  />
                ),
                okText: t('revealConversation.confirmText'),
                okType: 'primary',
                cancelText: t('revealConversation.cancelText'),
                centered: true,
                onOk() {
                  setIsAnonymous(false);
                },
                onCancel() {},
              })
            }
          />
        </RightCol>
      </StyledRow>
      <Col span={24}>
        <StyledSelect
          mode="multiple"
          placeholder="Select Channel"
          options={tagsList}
          optionFilterProp="label"
        />
      </Col>
      <Message
        jobRole={options.find((ele) => ele.value === selectedJobRole)}
        messages={messageList}
        isAnonymous={isAnonymous}
        isDrawer={isDrawer}
      />
      <Footer isDrawer={isDrawer}>
        <StyledInput
          autoSize={true}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <StyledButton
          disabled={!input}
          onClick={onSubmit}
          type={'primary'}
          icon={<SendOutlined />}
          size="large"
        />
        <StyledButton
          danger
          type="primary"
          icon={<CloseOutlined />}
          size="large"
          onClick={showClearConfirmation}
          disabled={messageList.length < 1}
        />
      </Footer>
    </>
  );
};

SuggestionBox.propTypes = {
  isDrawer: PropTypes.bool,
};

export default SuggestionBox;
