import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Mentions } from 'antd';
import { useSelector } from 'react-redux';

// Icons
import { SendOutlined } from '@ant-design/icons';
import CoinsIcon from './../assets/coins.svg';

// utils
import { translate } from './../i18n/i18n';

const t = translate(['recognition']);

const StyledInput = styled(Mentions)`
  &.ant-mentions {
    background: #f2f6f9;
    border: 1px solid #f2f6f9;
    border-radius: 2px;
    padding: 10px;
    max-height: 44px;
  }

  textarea {
    padding: 0px;
  }
`;

const StyledButton = styled(Button)`
  &&&.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 21px 23px;
    // color: #fff;
    // background-color: #1677ff;
    margin-left: 10px;
  }
`;

const StyledWarningButton = styled(StyledButton)`
  &&&.ant-btn {
    background-color: #ffac33;
  }
`;

const RecognitionForm = ({
  users = [],
  tags = [],
  occasion = [],
  onShowForm,
  onSendMessage,
  selectedMessage,
}) => {
  const { currentUser } = useSelector((state) => state.user);
  const [input, setInput] = useState('');
  const [prefix, setPrefix] = useState('');
  const [values, setValues] = useState([]);
  const [selectedOccasion, setSelectedOccasion] = useState([]);
  const [mentionedUser, setMentionedUser] = useState([]);

  const onSearch = (_, newPrefix) => {
    setPrefix(newPrefix);
  };

  const onResetForm = () => {
    setInput('');
    setMentionedUser([]);
    setValues([]);
    setSelectedOccasion([]);
    setValues([]);
    setPrefix('');
  };

  const onSubmit = () => {
    // console.log('input', input);
    onSendMessage({
      message: input.trim(),
      values:
        values.length > 0
          ? [...new Map(values.map((item) => [item['id'], item])).values()]
          : [],
      occasion:
        selectedOccasion.length > 0
          ? [
              ...new Map(
                selectedOccasion.map((item) => [item['id'], item]),
              ).values(),
            ]
          : [],
      users:
        mentionedUser.length > 0
          ? [
              ...new Map(
                mentionedUser.map((item) => [item['id'], item]),
              ).values(),
            ]
          : [],
    });
    onResetForm();
  };

  const onSelect = (option, prefix) => {
    if (prefix === '#') {
      values.push(option);
      setValues([...values]);
    } else if (prefix === '/') {
      selectedOccasion.push(option);
      setSelectedOccasion([...selectedOccasion]);
    } else {
      mentionedUser.push(option);
      setMentionedUser([...mentionedUser]);
    }
  };

  return (
    <>
      <StyledInput
        onChange={(e) => setInput(e)}
        value={input}
        autoSize={true}
        onSearch={onSearch}
        options={prefix === '@' ? users : prefix == '/' ? occasion : tags}
        prefix={['#', '@', '/']}
        placeholder={
          selectedMessage ? t('replyPlaceholder') : t('commentPlaceholder')
        }
        onSelect={onSelect}
      />
      <StyledButton
        onClick={onSubmit}
        type="primary"
        icon={<SendOutlined />}
        size="large"
      />
      {currentUser?.company?.coins_enabled && (
        <StyledWarningButton
          danger
          type="primary"
          icon={<img src={CoinsIcon} height={25} />}
          size="large"
          onClick={onShowForm}
        />
      )}
    </>
  );
};

RecognitionForm.propTypes = {
  onShowForm: PropTypes.func,
  onSendMessage: PropTypes.func,
  users: PropTypes.array,
  tags: PropTypes.array,
  occasion: PropTypes.array,
  selectedMessage: PropTypes.any,
};
export default RecognitionForm;
