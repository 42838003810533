import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('../views/Home'));
const Login = lazy(() => import('../views/Login'));
const Session = lazy(() => import('../views/Session'));
const Nudge = lazy(() => import('../views/Nudge'));
const NoPage = lazy(() => import('../views/404'));
const BehaviourSurvey = lazy(() => import('../views/BehaviourSurvey'));

const DefaultRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login/:slug" element={<Login />} />
      <Route path="/nudge/:nudgeId" element={<Nudge />} />
      <Route path="/session/:sessionId" element={<Session />} />
      <Route path="/behaviour" element={<BehaviourSurvey />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default DefaultRouter;
